import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
// import CouponCode from "./CouponCode";
// import AddCertificate from "./AddCertificate";
import ButtonLoader from "../form/ButtonLoader";
import CurrencyFormatter from "./CurrencyFormatter";
import { CartContext } from "../../context/CartContext";
import { LINE_ITEM_FIXED_LIMIT } from "../../utils/common";
import useToggle from "../../hooks/useToggle";
import SaveYourCartForm from "./SaveYourCartForm";
import ReactModal from "../form/Dialog/NewModal";
import useCustomerGroupRestriction from "../../hooks/customer/useCustomerGroupRestriction";
import ClearCartConfirmModal from "./ClearCartConfirmModal";
import useCheckout from "../../hooks/cart/useCheckout";

function CartFooter({
  cartType,
  cart,
  lineItemsLength,
  removeItems,
  isCheckoutDisable,
}) {
  const { isCheckoutLoading, handleCheckoutClick } = useCheckout();

  const [isOpen, toggleDialog] = useToggle(false);

  const { clearCart, checkoutLoading, isFallbackCheckoutLoading } = useContext(CartContext);
  const { isCustomerRestricted } = useCustomerGroupRestriction();
  const [open, setOpen] = useState(false);

  const onClickClearCart = () => {
    setOpen(true);
  };

  const onCancelClearCart = () => {
    setOpen(!open);
  };

  // render footer only when cart type is 'full' ...
  if (cartType === "full") {
    return (
      <>
        {lineItemsLength > 0 && (
          <div className="cart-bottom-action-section">
            {!isCustomerRestricted ? (
              <span
                className="button button--secondary button-small"
                onClick={() => toggleDialog()}
                disabled={isCheckoutLoading || checkoutLoading}
                role="button"
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    toggleDialog();
                  }
                }}
              >
                Save My Cart
              </span>
            ) : null}
            <div className="bc-cart-actions flex flex-wrap align-right vertical-middle">
              <span
                className="button button-border button-small"
                // onClick={() => clearCart()}
                onClick={() => onClickClearCart()}
                role="button"
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    onClickClearCart();
                  }
                }}
              >
                Clear Entire cart
              </span>
              <span
                className="button button-small button-border"
                onClick={() => removeItems()}
                role="button"
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    removeItems();
                  }
                }}
              >
                Remove Selected Items from Cart
              </span>
            </div>
          </div>
        )}

        <footer className="bc-cart-footer">
          <div className="cart-totals">
            <ul className="cart-total">
              {/* <li>
                <div className="col">
                  <strong>{`SubTotal:`}</strong>
                </div>
                <div className="col align-right">
                  <strong className="big">
                    <CurrencyFormatter
                      amount={cart?.["base_amount"] || 0}
                      currency={cart.currency.code}
                    />
                  </strong>
                </div>
              </li> */}

              {/* <li>
                <CouponCode cartCoupons={cart["coupons"]} />
              </li>
              <li>
                <AddCertificate />
              </li> */}

              <li className="cart-grand-total">
                <div className="col">
                  <strong>{`Grand Total:`}</strong>
                </div>
                <div className="col align-right grand-total-price">
                  <strong>
                    <CurrencyFormatter
                      amount={cart?.["cart_amount"] || 0}
                      currency={cart.currency.code}
                    />
                  </strong>
                </div>
              </li>
            </ul>
          </div>

          {lineItemsLength > 0 && (
            <div className="bc-cart-actions bc-cart-actions-bottom flex flex-wrap align-right vertical-middle">
              {!isCustomerRestricted ? (
                <div className="cart-col-action">
                  {isCheckoutLoading || checkoutLoading || isFallbackCheckoutLoading ? (
                    <ButtonLoader />
                  ) : (
                    <button
                      className="bc-btn bc-cart-actions__checkout-button button button-large"
                      onClick={() => handleCheckoutClick(true)}
                      disabled={
                        isCheckoutDisable ||
                        (lineItemsLength >= LINE_ITEM_FIXED_LIMIT
                          ? true
                          : false)
                      }
                    >
                      Proceed to Checkout
                    </button>
                  )}
                </div>
              ) : null}
              {/* <div className="col">
                {checkoutLoading["isCustomCheckoutLoading"] ? (
                  <ButtonLoader />
                ) : (
                  <button
                    className="bc-btn bc-cart-actions__checkout-button button button-large"
                    onClick={() =>
                      checkoutCart(false, true, "isCustomCheckoutLoading")
                    }
                    disabled={
                      isCheckoutDisable ||
                      (lineItemsLength >= LINE_ITEM_FIXED_LIMIT ? true : false)
                    }
                  >
                    Custom Checkout
                  </button>
                )}
              </div> */}
              {/* <div className="col">
                {checkoutLoading["isExternalCheckoutLoading"] ? (
                  <ButtonLoader />
                ) : (
                  <button
                    className="bc-btn bc-cart-actions__checkout-button button button-large"
                    onClick={() =>
                      checkoutCart(true, false, "isExternalCheckoutLoading")
                    }
                    disabled={
                      isCheckoutDisable ||
                      (lineItemsLength >= LINE_ITEM_FIXED_LIMIT ? true : false)
                    }
                  >
                    External Checkout
                  </button>
                )}
              </div> */}
              {isOpen && (
                <ReactModal
                  isOpen={isOpen}
                  setIsOpen={() => toggleDialog()}
                  title={"Save your cart"}
                >
                  <SaveYourCartForm onClose={toggleDialog} />
                </ReactModal>
              )}
              {open && (
                <ReactModal
                  isOpen={open}
                  setIsOpen={onCancelClearCart}
                  title="Confirm Clear Cart"
                >
                  <ClearCartConfirmModal
                    clearCart={clearCart}
                    onClose={onCancelClearCart}
                  />
                </ReactModal>
              )}
            </div>
          )}
        </footer>
      </>
    );
  } else <></>;
}

CartFooter.propTypes = {
  isCheckoutDisable: PropTypes.bool,
  cartType: PropTypes.string,
  cart: PropTypes.object,
  lineItemsLength: PropTypes.number,
  removeItems: PropTypes.func,
};

export default CartFooter;
